<template>
  <div class="breadcrumbs-wrapper">
    <p><router-link to="">Active Projects</router-link> /</p>
  </div>

  <div class="cards-wrapper">
    <div class="cards-bg">
      <div class="filters-wrapper">
        <div>
          <div class="filters_search">
            <label>Search:<input v-model="searchTerm" /></label>
          </div>

          <div class="filters_icon"><IconFilter height="18" /></div>

          <div
            class="filters_select"
            v-if="categoryOptions && categoryOptions.length"
          >
            <label>Select Category: </label>
            <select v-model="selectedCategory">
              <option value="">All projects</option>
              <option
                v-for="category in categoryOptions"
                :value="category.id"
                :key="category.id"
              >
                {{ category.title }}
              </option>
            </select>
          </div>
        </div>

        <div class="view-toggler" v-if="viewTogglerEnabled">
          <IconShow1 @click="tableView = false" />
          <IconShow2 @click="tableView = true" />
        </div>
      </div>

      <div v-if="tableView">
        <div class="project-table-wrapper">
          <div class="project-table">
            <Table
              project-type="active"
              :columns="table.columns"
              :rows="table.rows"
              :total="table.totalRecordCount"
              :pagination="pagination"
              :sortable="table.sortable"
              @do-sort="doSort"
              @paginate="paginate"
            ></Table>
          </div>
        </div>
      </div>

      <div v-else>
        <div v-if="projects && projects.length" class="cards">
          <CardMiniDescription
            v-for="project in projects"
            :key="project.project_number"
            :cardData="project"
            :is-admin="false"
            @click="goToProjectDetails(project.id)"
          />
        </div>
        <h4 v-else>No active project</h4>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, reactive, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Table from "@/components/table/table.vue";
import CardMiniDescription from "@/modules/components/base/CardMiniDescription.vue";
import IconFilter from "@/modules/components/icons/IconFilter.vue";
import IconShow1 from "@/modules/components/icons/IconShow1.vue";
import IconShow2 from "@/modules/components/icons/IconShow2.vue";

export default {
  components: { Table, CardMiniDescription, IconFilter, IconShow1, IconShow2 },
  setup() {
    const store = useStore();
    const router = useRouter();

    const tableView = ref(true);
    //ToDo: remove view toggler after 09.12.2021 if not needed
    const viewTogglerEnabled = ref(false);
    const parameters = ref({
      t: "a", // r, f, a, arc, reop
      page: 1,
      // s: "bus",
      // so: "created_at",
      // sw: "desc",
      // c: 2, //category.id
    });
    store.dispatch("projects/getActiveProjects");
    const projects = computed(() => store.getters["projects/projects"]);

    const categoryOptions = computed(
      () => store.getters["projects/projectCategories"]
    );
    const pagination = computed(() => store.getters["projects/pagination"]);
    const selectedCategory = ref("");
    const searchTerm = ref("");

    let table = reactive({
      isLoading: false,
      isReSearch: false,
      columns: [
        {
          label: "Status",
          field: "notify",
          width: "5%",
          sortable: true,
        },
        {
          label: "Feasibility#",
          field: "feasibility_number",
          width: "10%",
          sortable: true,
        },
        {
          label: "Project#",
          field: "project_number",
          width: "10%",
          sortable: true,
        },
        {
          label: "Request Title",
          field: "description_short",
          width: "20%",
          sortable: false,
        },
        {
          label: "Creation Date",
          field: "created_at",
          width: "20%",
          sortable: true,
        },
        {
          label: "Timeline",
          field: "timeline",
          width: "10%",
          sortable: false,
        },
        {
          label: "Indication",
          field: "indication",
          width: "20%",
          sortable: false,
        },
        {
          label: "Pathology",
          field: "pathology",
          width: "20%",
          sortable: true,
        },
      ],
      rows: [],
      sortable: {
        order: "id",
        sort: "asc",
      },
    });

    table.rows = computed(() => {
      if (!projects.value || !projects.value.length) {
        return [];
      }

      return projects.value.map((project) => {
        let p = {};
        p.feasibility_number = project.feasibility_number || "";
        p.project_number = project.project_number;
        p.description_short = project.description_short
          ? project.description_short
          : "";
        let creationDate = project.created_at;
        p.created_at = creationDate.slice(0, 10);
        p.timeline = project.timeline;
        p.id = project.id;
        const pathologies = project.categories.map((c) => c.title);
        p.pathology = pathologies.join(", ");
        p.indication = project.indication ? project.indication : "";
        p.notify = {
          hasMsg: !!project.new_messages,
          // hasProposal: !!project.new_proposals,
        };
        return p;
      });
    });

    const getFilteredProjects = () => {
      store.dispatch("projects/getFilteredProjects", {
        params: parameters.value,
      });
    };

    const doSort = (order, sort) => {
      parameters.value.sw = sort;
      parameters.value.so = order;
      getFilteredProjects();
    };

    const paginate = (to) => {
      if (parameters.value.c || parameters.value.s) {
        parameters.value = { ...parameters.value, ...{ page: to } };
        getFilteredProjects();
      } else {
        store.dispatch("projects/getActiveProjects", to);
      }
    };

    watch(searchTerm, (newTerm) => {
      parameters.value.s = newTerm;
      if (!newTerm.length) {
        delete parameters.value.s;
      } else {
        parameters.value.page = 1;
      }
      getFilteredProjects();
    });

    watch(selectedCategory, (newCategory) => {
      parameters.value.c = newCategory;
      if (!newCategory) {
        delete parameters.value.c;
      } else {
        parameters.value.page = 1;
      }
      getFilteredProjects();
    });

    const goToProjectDetails = (id) => {
      router.push({
        path: `/feasibility-project-details/${id}`,
        params: { id: id },
      });
    };

    return {
      table,
      tableView,
      projects,
      pagination,
      searchTerm,
      categoryOptions,
      selectedCategory,
      doSort,
      getFilteredProjects,
      goToProjectDetails,
      viewTogglerEnabled,
      paginate,
    };
  },
};
</script>

<style lang="scss" scoped>
.cards {
  &-wrapper {
    min-height: 100%;
  }

  &-bg {
    min-height: calc(100vh - 190px);
    padding: 23px 38px;
    background-color: var(--col-bg-secondary);
  }

  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
  // padding: 20px;
}

h4 {
  padding: 30px;
  text-align: center;
}
</style>
